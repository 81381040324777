var breakpoint = {};
breakpoint.refreshValue = function () {
    this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/["']/g, '');
    this.float = window.getComputedStyle(document.querySelector('body'), ':after').getPropertyValue('content').replace(/["']/g, '');

};

jQuery(document).ready(function($){
    $('html').on('contextmenu', function(e){
        e.stopPropagation();
        alert('Hinweis: Die Bilder und Grafiken dieser Website dürfen nicht kopiert oder in anderem Zusammenhang verwendet werden.\n\nDr. Otto Suwelack Nachf. GmbH & Co.KG', 'Hinweis');
    });
});

jQuery(document).ready(function($){
    $(window).resize(function () {
        breakpoint.refreshValue();
    }).resize();
});


jQuery(document).ready(function($){
    moveNavigation();
    $(window).on('resize', function(){
        (!window.requestAnimationFrame) ? setTimeout(moveNavigation, 300) : window.requestAnimationFrame(moveNavigation);
    });
});



function moveNavigation(){
    $('.nav-wrapper-clone').detach();
    var navigation = $('#navbar');
    var navigationWrapper = $('.nav-wrapper');
    if ( breakpoint.value == 'md' || breakpoint.value == 'lg' || breakpoint.value == 'xl') {
        //desktop screen - insert inside header element
        navigation.detach();
        navigationWrapper.detach();
        navigation.insertBefore('.app');
        navigationWrapper.insertAfter('.nav-trigger');

        if ($('.burger').length > 0 && !$('.nav-trigger').is(':visible')) {
            var $navCloneDiv = $('<div>', {class: 'nav-wrapper-clone'});
            var $navClone = navigationWrapper.clone();
            if ($('.app').hasClass('nav-is-visible')) {
                $navClone.addClass('nav-is-visible');
            }
            $navClone.appendTo($navCloneDiv);
            $('.app').after($navCloneDiv);
        }
    } else {
        //mobile screen - insert after .main-content element

        $('.app').removeClass('nav-is-visible');
        $('#navbar').removeClass('nav-is-visible');
        $('.nav-wrapper').removeClass('nav-is-visible');

        navigation.detach();
        navigationWrapper.detach();
        navigation.insertAfter('.app');
        navigationWrapper.insertAfter('.app');
    }
}


jQuery(document).ready(function($){
    var init = function () {
        var body = $('body');
        initContent(body);
    };

    var initContent = function (content) {
        //mobile version - open/close navigation
        content.find('.nav-trigger').on('click', function(event) {
            event.preventDefault();

            $('.app').toggleClass('nav-is-visible');
            $('#navbar').toggleClass('nav-is-visible');
            $('.nav-wrapper').toggleClass('nav-is-visible');
        });

        if ($('.burger').length > 0 && !$('.nav-trigger').is(':visible')) {
            content.find('.nav-trigger-clone').on('click', function(event) {
                event.preventDefault();

                $('.app').toggleClass('nav-is-visible');
                $('#navbar').toggleClass('nav-is-visible');
                $('.nav-wrapper-clone .nav-wrapper').toggleClass('nav-is-visible');
            });
        }

        content.find('.slider').owlCarousel({
            //animateIn: 'zoomIn',
            //animateOut: 'zoomOut',
            autoplay: true,
            loop: true,
            margin: 15,
            nav: true,
            dots: true,
            items: 1,
            navText: [
              '<i class="icon-carousel-left"></i>',
              '<i class="icon-carousel-right"></i>'
              ]
        });

        content.find('.site-search').each(function() {
            var $search = $(this);
            $search.on('focusin', '.site-search-input', function () {
                $search.addClass('site-search-focus');
            });
            $search.on('focusout', '.site-search-input', function () {
                $search.removeClass('site-search-focus');
            });
        })
    };
    init();
});
